// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import pageData from 'helpers/pageData';
import { getId } from 'helpers/getId';
import { getContextUrl } from 'helpers/getContextUrl';
import { aspDotNetDomainSettingsRoute } from 'routes/aspDotNetDomainSettingsRoute';
import { bandwidthLimitingDomainRoute } from 'routes/bandwidthLimitingDomainRoute';
import { hotlinkProtectionDomainRoute } from 'routes/hotlinkProtectionDomainRoute';
import { odbcDomainRoute } from 'routes/odbcDomainRoute';
import { protectedDirectoriesRoute } from 'routes/protectedDirectoriesRoute';
import { virtualDirectoryRoute } from './virtualDirectoryRoute';
import { trafficUsageByDomainRoute } from 'routes/trafficUsageByDomainRoute';
import apolloClient from '../apolloClient';

import DOMAIN_PAGE from 'queries/DomainPage.graphql';

export const domainRoute = {
    path: 'domains/:domainId',
    loader: async ({ params }) => {
        const id = btoa(`Domain:${params.domainId}`);

        const { data } = await apolloClient.query({
            query: DOMAIN_PAGE,
            variables: { id },
        });

        return data;
    },
    handle: {
        crumb: ({ data, matchesContext }) => {
            matchesContext.domainRouteData = data;

            const { domain } = data;
            let { baseDomain } = data;
            baseDomain = domain.baseDomain || domain;

            const { pageSidebar } = pageData();
            const { isPowerUserPanel, navigationContext } = pageSidebar;

            const domainId = getId(domain.id);
            const baseDomainId = getId(baseDomain.id);

            const crumbs = [
                {
                    title: Locale.getSection('components.routes').lmsg('subscriptions'),
                    href: '/admin/subscription/list',
                    show: !isPowerUserPanel && navigationContext === 'subscriptions',
                },
                {
                    title: `<b>${baseDomain.name}</b>`,
                    href: `/admin/subscription/overview/id/${baseDomainId}`,
                    show: !isPowerUserPanel && navigationContext === 'subscriptions',
                },
                {
                    title: Locale.getSection('components.routes').lmsg('domains'),
                    href: '/admin/domain/list',
                    show: !isPowerUserPanel && navigationContext === 'domains',
                },
                {
                    title: Locale.getSection('components.routes').lmsg('web'),
                    href: getContextUrl(baseDomainId, '/smb/web/view'),
                    show: isPowerUserPanel || navigationContext === 'subscriptions',
                },
                {
                    title: domain.name,
                    href: getContextUrl(baseDomainId, `/smb/web/overview/id/${domainId}/type/domain`),
                },
            ].filter(({ show = true }) => show);

            return crumbs;
        },
    },
    children: [
        aspDotNetDomainSettingsRoute,
        bandwidthLimitingDomainRoute,
        hotlinkProtectionDomainRoute,
        odbcDomainRoute,
        protectedDirectoriesRoute,
        virtualDirectoryRoute,
        trafficUsageByDomainRoute({ path: 'traffic-usage' }),
    ],
};
