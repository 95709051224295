// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

export const FTYPE_DIR = 'dir';
export const FTYPE_TEXT = 'text';
export const FTYPE_IMAGE = 'image';
export const FTYPE_VECTOR_IMAGE = 'vector_image';
export const FTYPE_AUDIO = 'audio';
export const FTYPE_SECURITY = 'security';
export const FTYPE_WEBSCRIPT = 'webscript';
export const FTYPE_WEBPAGE = 'webpage';
export const FTYPE_WAPPAGE = 'wappage';
export const FTYPE_COMPRESSED = 'compressed';
export const FTYPE_UNKNOWN_TEXT = 'unknown_text';
export const FTYPE_UNKNOWN_BINARY = 'unknown_binary';
export const FTYPE_ZIP = 'zip';
export const FTYPE_RAR = 'rar';
export const FTYPE_TAR = 'tar';
export const FTYPE_TGZ = 'tgz';

export const getTypeIcon = ({ type, isDirectory, name }) => {
    if (isDirectory) {
        return name === '..'
            ? require('icons/16/plesk/uplevel.png')
            : require('icons/16/plesk/file-folder.png');
    }

    const list = {
        [FTYPE_TEXT]: require('icons/16/plesk/file-txt.png'),
        [FTYPE_IMAGE]: require('icons/16/plesk/file-image.png'),
        [FTYPE_VECTOR_IMAGE]: require('icons/16/plesk/file-image.png'),
        [FTYPE_AUDIO]: require('icons/16/plesk/file-audio.png'),
        [FTYPE_SECURITY]: require('icons/16/plesk/file-private.png'),
        [FTYPE_WEBSCRIPT]: require('icons/16/plesk/file-webscript.png'),
        [FTYPE_WEBPAGE]: require('icons/16/plesk/file-html.png'),
        [FTYPE_WAPPAGE]: require('icons/16/plesk/file-html.png'),
        [FTYPE_COMPRESSED]: require('icons/16/plesk/file-arch.png'),
        [FTYPE_UNKNOWN_TEXT]: require('icons/16/plesk/file-txt.png'),
        [FTYPE_UNKNOWN_BINARY]: require('icons/16/plesk/file-binary.png'),
        [FTYPE_ZIP]: require('icons/16/plesk/file-arch.png'),
        [FTYPE_RAR]: require('icons/16/plesk/file-arch.png'),
        [FTYPE_TAR]: require('icons/16/plesk/file-arch.png'),
        [FTYPE_TGZ]: require('icons/16/plesk/file-arch.png'),
    };

    return list[type];
};

export const toUnixPermissionsString = permissions => [
    'ownerRead',
    'ownerWrite',
    'ownerExecute',
    'groupRead',
    'groupWrite',
    'groupExecute',
    'othersRead',
    'othersWrite',
    'othersExecute',
].reduce((acc, name, index) => {
    const flag = permissions[name];
    acc += flag ? ['r', 'w', 'x'][index % 3] : '-';
    if (index % 3 === 2) {
        acc += ' ';
    }
    return acc;
}, '');
