// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import PropTypes from 'prop-types';
import { List, Pagination } from '@plesk/ui-library';
import { GraphQlListContext } from './GraphQlListContext';
import GraphQlListSearchBar from './GraphQlListSearchBar';

const GraphQlList = ({
    onSortChange = undefined,
    filtered = undefined,
    pagination = undefined,
    emptyView = undefined,
    emptyViewFiltered = undefined,
    listContext,
    ...listProps
}) => {
    const { paginationProps, setSort } = listContext;

    const [prevFiltered, setPrevFiltered] = useState(filtered);
    const isLoadingOrFiltered = filtered || (listProps.loading && prevFiltered);
    if (!listProps.loading && prevFiltered !== filtered) {
        setPrevFiltered(filtered);
    }

    return (
        <GraphQlListContext.Provider value={listContext}>
            <List
                filtered={isLoadingOrFiltered}
                pagination={pagination || (paginationProps && <Pagination {...paginationProps} />)}
                emptyView={isLoadingOrFiltered ? emptyViewFiltered : emptyView}
                {...listProps}
                onSortChange={(...args) => {
                    onSortChange?.(...args);
                    const [sort] = args;
                    setSort({ [sort.sortColumn]: sort.sortDirection });
                }}
            />
        </GraphQlListContext.Provider>
    );
};

GraphQlList.propTypes = {
    onSortChange: PropTypes.func,
    listContext: PropTypes.object.isRequired,
    filtered: PropTypes.bool,
    pagination: PropTypes.node,
    emptyView: PropTypes.node,
    emptyViewFiltered: PropTypes.node,
};

GraphQlList.SearchBar = GraphQlListSearchBar;

export default GraphQlList;
